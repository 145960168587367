import { getActivePinia } from 'pinia'

import { useUserStore } from '~/stores/user'

/**
 * This module will replace `/` route with the /lp/[slug.vue] page
 * If loaded before the i18n module new i18n routes will be automaticaly added.
 */
export default defineNuxtRouteMiddleware(() => {
  const { $localePath } = useNuxtApp()
  const userStore = useUserStore(getActivePinia())

  if (userStore.IS_INCOMPLETE_USER) return
  if (userStore.IS_INF) return navigateTo($localePath('/influencer/dashboard/'))
  if (userStore.IS_BAND) return navigateTo($localePath('/band/homepage/'))
})
